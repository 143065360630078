import * as React from "react";
import "./styles.css";
import { BookingForm } from "./RoomBooking";
const App = () => {
  return (
    <div>
      <BookingForm />
    </div>
  );
};
export default App;
