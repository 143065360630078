import React, { useState, createRef, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
} from "@progress/kendo-react-upload";
import axios from "axios";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { MyDatePicker } from "./MyDatePicker";
import { Icon } from "@progress/kendo-react-common";
import "../src/RoomBooking.scss";
import "../src/Common.scss";

interface FormErrors {
  spaceDateErr?: string;
  spaceDurationErr?: string;
  companyNameErr?: string;
  customerNameErr?: string;
  customerMobileErrr?: string;
  customerEmailErrr?: string;
  customerAddresEerrr?: string;
  customerKYCDocumentErrr?: string;
  customerKYCFileErrr?: string;
  bookingFromErrr?: string;
  bookingToErrr?: string;
}

interface ErrorRequired {
  companyNameErr?: boolean;
  customerNameErr?: boolean;
  customerMobileErrr?: boolean;
  customerEmailErrr?: boolean;
  customerAddresEerrr?: boolean;
  customerKYCDocumentErrr?: boolean;
}

export const BookingForm = () => {
  const baseURL = `https://live-api.workfromhaven.in/`;

  // Defaut values
  const defaultDurationValue = { DurationName: "Select Duration" };
  const defaultKYCDicumentValue = { Name: "Select KYC Document" };
  const defaultFromTimeValue = "Select From Time";
  const defaultToTimeValue = "Select To Time";
  const mobileRegex = /^[6-9][0-9]{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Top filters
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [selectedFromTime, setSelectedFromTime] =
    useState(defaultFromTimeValue);
  const [selectedToTime, setSelectedToTime] = useState(defaultToTimeValue);
  const [selectedDuration, setSelectedDuration] =
    useState(defaultDurationValue);
  const [selectedDurationID, setSelectedDurationID] = useState<string>();
  const [selectedDurationName, setSelectedDurationName] = useState<string>();
  const [selectedKYC, setSelectedKYC] = useState(defaultKYCDicumentValue);
  const [selectedKYCID, setSelectedKYCID] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [selectedKYCName, setSelectedKYCName] = useState<string>();
  const [orginalTime, setOrginalTime] = useState(8);

  // Edit field
  const [selectedValue, setSelectedValue] = useState("Company");
  const [roomBookFormData, setRoomBookFormData] = useState({
    CompanyName: "",
    bookingFrom: "",
    bookingTo: "",
    customerName: "",
    contactNo: "",
    email: "",
    address: "",
    gst: "",
    notes: "",
  });
  const [roomBookErrorFormData, setRoomBookErrorFormData] =
    useState<FormErrors>();
  const [isCheckRequired, setIsCheckRequired] = useState<ErrorRequired>();

  // API send
  const [durations, setDurations] = useState([]);
  const [inclusionPackageDetails, setInclusionPackageDetails] = useState("");
  const [exclusionPackageDetails, setExclusionPackageDetails] = useState("");
  var [currentName, setCurrentName] = useState("Contact Person");
  var [currentAddress, setCurrentAddress] = useState("Company");
  const handleChange = useCallback(
    (e: any) => {
      setSelectedValue(e.value);
      resetDatas(0);
      if (e.value === "Individual") {
        setCurrentName("Your");
        setCurrentAddress("");
        setRoomBookErrorFormData({});
        setIsCheckRequired({});
      } else {
        setCurrentName("Contact Person");
        setCurrentAddress("Company");
        setRoomBookErrorFormData({});
        setIsCheckRequired({});
      }
    },
    [setSelectedValue]
  );
  const uploadRefs = createRef<Upload>();
  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = <span>Only JPEG, JPG, PNG and PDF files are allowed.</span>;
  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };
  const [imageData, setImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const [loading, setLoading] = useState(true);
  const [spaceData, setSpaceData] = useState({
    Id: 0,
    SpaceType: "",
    NoOfSeats: 0,
    Description: "",
    DurationId: 0,
    PackageDetailsId: 0,
    RentAmount: 0,
    Tax: 0,
    SpaceImage: "",
  });
  const [spaceDetailsData, setSpaceDetailsData] = useState([]);
  const [kycDetailsData, setkycDetailsData] = useState([]);
  const [subTotal, setsubTotal] = useState(0);
  const [subTotalIncrease, setsubTotalIncrease] = useState(0);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [dummyTotal, setdummyTotal] = useState(0);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [spaceVisible, setSpaceVisible] = React.useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const resetDatas = (selection: any) => {
    if (selection === 1) {
      setIsCheckHourly(true);
      const errors: FormErrors = {};
      errors.spaceDateErr = "";
      errors.spaceDurationErr = "";
      setsubTotalIncrease(0);
      setTaxAmount(0);
      setTotal(0);
      setdummyTotal(0);
      setDepositAmount(0);
      setSelectedDuration(defaultDurationValue);
      setCheckInDate(null);
      setSelectedFromTime(defaultFromTimeValue);
      setSelectedToTime(defaultToTimeValue);
      setRoomBookErrorFormData(errors);
    }
    roomBookFormData.CompanyName = "";
    roomBookFormData.bookingTo = "";
    roomBookFormData.customerName = "";
    roomBookFormData.contactNo = "";
    roomBookFormData.email = "";
    roomBookFormData.address = "";
    roomBookFormData.gst = "";
    roomBookFormData.notes = "";
    setSelectedKYC(defaultKYCDicumentValue);
    setImageData("");
    setRoomBookFormData(roomBookFormData);
  };

  // Save file upload local
  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFile(currentFile);
    const uid = currentFile.uid;

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  // Remove file upload local
  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  const [isCheckHourly, setIsCheckHourly] = useState(true);
  // Initial load API's
  useEffect(() => {
    const getSpace = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}odata/Spaces`);
        setSpaceDetailsData(response.data.value);
        handleDropdownListOpen();
        getSpaceDetails(response.data.value[0].Id);
      } catch (err) {
        alert(err);
      }
    };

    getSpace();
  }, []);

  // Get space details API
  const getSpaceDetails = async (spaceId: any) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}odata/Spaces(${spaceId})`);
      setSpaceData(response.data.value[0]);
      getDurationDetails(response.data.value[0].Id);
      getPackageDetails(response.data.value[0].PackageDetailsId);
    } catch (err) {
      alert(err);
    }
  };

  // Get space package details API
  const getPackageDetails = async (packageId: String) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/PackageDetails(${packageId})`
      );
      setInclusionPackageDetails(response.data.value[0].Inclusion);
      setExclusionPackageDetails(response.data.value[0].Exclusion);
    } catch (err) {
      alert(err);
    }
  };

  // Get duration details API
  const getDurationDetails = async (spaceId: String) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/SpaceMappings?$filter=SpaceId%20eq%20${spaceId}`
      );
      setDurations(response.data.value);
      getKYCDetails();
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  const calculateBookingAmount = (
    RentAmount: any,
    Tax: any,
    checkFlow: any,
    difference: any,
    deposit: any
  ) => {
    let subTotalValue = RentAmount;
    let gstValue = Tax;
    let depositValue = deposit;
    if (checkFlow === "Others") {
      setsubTotalIncrease(subTotalValue);
      const calculateTax = (subTotalValue * gstValue) / 100;
      const calculateTotal = subTotalValue + calculateTax;
      setTaxAmount(calculateTax);
      setTotal(calculateTotal);
      const addedDiscount = calculateTotal + depositValue;
      setdummyTotal(addedDiscount);
    } else if (checkFlow === "Hourly" && difference !== 0) {
      let subTotalValue = subTotal * difference;
      setsubTotalIncrease(subTotalValue);
      const calculateTax = (subTotalValue * gstPercentage) / 100;
      const calculateTotal = subTotalValue + calculateTax;
      setTaxAmount(calculateTax);
      setTotal(calculateTotal);
      const addedDiscount = calculateTotal + depositValue;
      setdummyTotal(addedDiscount);
    } else {
      setsubTotalIncrease(0);
      setTaxAmount(0);
      setTotal(0);
      setdummyTotal(0);
    }
  };

  // Get KYC dicument details API
  const getKYCDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/KycDocuments`);
      setkycDetailsData(response.data.value);
    } catch (err) {
      setLoading(false);
      alert(err);
    }
    setLoading(false);
  };
  //

  // Validate booking fields
  const validateForm = () => {
    const errors: FormErrors = {};
    const isCheckErrors: ErrorRequired = {};
    // Company Name validation
    if (selectedValue === "Company" && !roomBookFormData.CompanyName.trim()) {
      errors.companyNameErr = "Company name is required";
      isCheckErrors.companyNameErr = true;
    }

    // Name validation
    if (!roomBookFormData.customerName.trim()) {
      if (selectedValue === "Company") {
        errors.customerNameErr = "Contact person name is required";
        isCheckErrors.customerNameErr = true;
      } else {
        errors.customerNameErr = "Your name is required";
        isCheckErrors.customerNameErr = true;
      }
    }

    // Mobile number validation (10 digits)
    if (!roomBookFormData.contactNo.trim()) {
      errors.customerMobileErrr = "Mobile number is required";
      isCheckErrors.customerMobileErrr = true;
    } else if (!mobileRegex.test(roomBookFormData.contactNo)) {
      errors.customerMobileErrr = "Invalid mobile number and must be 10 digits";
      isCheckErrors.customerMobileErrr = true;
    }

    // Email validation
    if (!roomBookFormData.email.trim()) {
      errors.customerEmailErrr = "Email address is required";
      isCheckErrors.customerEmailErrr = true;
    } else if (!emailRegex.test(roomBookFormData.email)) {
      errors.customerEmailErrr = "Invalid email address";
      isCheckErrors.customerEmailErrr = true;
    }

    // Address validation
    if (!roomBookFormData.address.trim()) {
      if (selectedValue === "Company") {
        errors.customerAddresEerrr = "Company address is required";
        isCheckErrors.customerAddresEerrr = true;
      } else {
        errors.customerAddresEerrr = "Address is required";
        isCheckErrors.customerAddresEerrr = true;
      }
    }

    // Booking date validation
    if (checkInDate === undefined || checkInDate === null) {
      errors.spaceDateErr = "Date is required.";
    } else if (checkInDate !== undefined) {
      const isoString = convertToISOString(checkInDate);
      const { dateObj, formattedDateTime } = convertToDateTime(isoString);

      const today = new Date();
      if (checkInDate instanceof Date && !isNaN(checkInDate.getTime())) {
        const todayDateOnly = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        const selectedDateOnly = new Date(
          checkInDate.getFullYear(),
          checkInDate.getMonth(),
          checkInDate.getDate()
        );
        if (selectedDateOnly < todayDateOnly) {
          setErrorMessage("Please select today or a future date.");
        } else {
          setErrorMessage(null);
        }
      }
    }

    // KYC File upload validation
    if (!imageData.trim()) {
      errors.customerKYCDocumentErrr = "KYC File is required";
      isCheckErrors.customerKYCDocumentErrr = true;
    }

    // File upload validation
    if (
      selectedDuration.DurationName === "Select Duration" ||
      selectedDurationName === "Select Duration"
    ) {
      errors.spaceDurationErr = "Duration is required";
    }

    // KYC Document upload validation
    if (
      selectedKYC.Name === "Select KYC Document" ||
      selectedKYCName === "Select KYC Document"
    ) {
      errors.customerKYCFileErrr = "KYC Document is required";
    }

    // Booking from time
    if (isCheckHourly === false) {
      if (selectedFromTime === "Select From Time") {
        errors.bookingFromErrr = "From time is required";
      }

      // Booking to time
      if (selectedToTime === "Select To Time") {
        errors.bookingToErrr = "To time is required";
      }
    }

    setRoomBookErrorFormData(errors);
    setIsCheckRequired(isCheckErrors);
    // Return whether the form is valid
    return Object.keys(errors).length === 0;
  };

  const convertToDateTime = (isoString: any) => {
    // Convert the ISO string to a Date object
    const dateObj = new Date(isoString);

    // Extract various components of the date and time if needed
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();

    // Format the date-time as you need (optional)
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return {
      dateObj,
      formattedDateTime, // Optional: For displaying the formatted date-time string
    };
  };

  var selectedBookingFromTime: Date;
  var selectedBookingToTime: Date;
  const [alertContent, setAlertContent] = React.useState<String>("");
  const savePersonalDetails = async (e: any) => {
    e.preventDefault();
    var bookingFromDate: Date = new Date(checkInDate ?? new Date());
    var bookingToDate: Date = new Date(checkInDate ?? new Date());
    bookingFromDate?.setHours(
      bookingFromDate.getHours() + parseInt(selectedFromTime)
    );
    selectedBookingFromTime = bookingFromDate;
    bookingToDate?.setHours(
      bookingToDate.getHours() + parseInt(selectedToTime)
    );
    selectedBookingToTime = bookingToDate;
    if (validateForm()) {
      setLoading(true);

      let newData = {
        userId: 1,
        roomTypeId: spaceData.Id,
        taxId: 1,
        bookingId: "",
        bookingDatetime: checkInDate,
        noOfSeats: spaceData.NoOfSeats,
        bookingForId: selectedValue === "Company" ? 1 : 2,
        address: roomBookFormData.address,
        companyName: roomBookFormData.CompanyName,
        kycDocumentId: selectedKYCID,
        gstNumber: roomBookFormData.gst,
        notes: roomBookFormData.notes,
        customerId: "",
        customerName: roomBookFormData.customerName,
        customerType: selectedValue,
        contactNo: parseInt(roomBookFormData.contactNo),
        email: roomBookFormData.email,
        totalAmount: total,
        discountPercentage: 0,
        discountValue: 0,
        packageSelection: spaceData.PackageDetailsId.toString(),
        bookingFrom:
          selectedFromTime === "Select From Time"
            ? checkInDate
            : selectedBookingFromTime,
        bookingTo:
          selectedToTime === "Select To Time"
            ? checkInDate
            : selectedBookingToTime,
        bookingStatus: 1,
        paymentStatus: 1,
        deposit: depositAmount,
        spaceRent: subTotalIncrease,
        otherAmount: 0,
        rawpowerStartingUnit: 0,
        rawpowerClosingUnit: 0,
        upspowerStartingUnit: 0,
        upspowerClosingUnit: 0,
        totalUnitConsumed: 0,
        perUnitCost: 0,
        overallCharges: 0,
        coffeeTea: 0,
        meals: 0,
        miscellaneous: 0,
        needToCollect: 0,
        refund: 0,
        durationId: selectedDurationID,
        spaceId: spaceData.Id,
      };
      try {
        const response = await axios.post(`${baseURL}odata/Bookings`, newData, {
          headers: header,
        });
        if (response.status === 201) {
          uploadDocumentFiles(response.data.Id);
        } else {
          setVisible(true);
          setAlertContent("Server not rechable. Please try again later!");
          setLoading(false);
        }
      } catch (err: any) {
        if (err.response.status === 500) {
          setVisible(true);
          setAlertContent(`${err.response.data.detail}`);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const convertToISOString = (dateStr: any) => {
    // Parse the date string into a JavaScript Date object
    const dateObj = new Date(dateStr);

    // Convert the Date object to ISO string in UTC format
    const isoString = dateObj.toISOString();

    return isoString;
  };

  const uploadDocumentFiles = async (bookingId: String) => {
    let uploadData = [
      {
        docUploadGuid: currentFile?.uid,
        docUploadRefType: "User",
        docUploadRefNo: bookingId,
        docUploadRefSubType: "Space",
        image: [
          {
            value: imageData,
            fileName: currentFile?.name,
            type: currentFile?.extension?.split(".").pop(),
          },
        ],
      },
    ];
    try {
      const response = await axios.post(
        `${baseURL}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        sendMail(bookingId);
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
        setLoading(false);
      }
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  const sendMail = async (bookingId: any) => {
    try {
      const response = await axios.post(
        `${baseURL}Odata/SendConfirmationEmail?email=${roomBookFormData.email}&bookingId=${bookingId}`,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        setVisible(true);
        setAlertContent(response.data.message);
        resetDatas(1);
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  const setTimeCalc = (value: any) => {
    const curDate = new Date();

    settoTimeEnable(true);
    setSelectedToTime(defaultToTimeValue);
    setSelectedFromTime(defaultFromTimeValue);
    if (
      curDate.getDate() === value.getDate() &&
      curDate.getMonth() === value.getMonth() &&
      curDate.getFullYear() === value.getFullYear()
    ) {
      setOrginalTime(curDate.getHours() + 1);
    } else {
      setOrginalTime(8);
    }
  };

  const [totimeEnable, settoTimeEnable] = useState(true);

  //Timelist for from and to time
  const generateTimeList = (): string[] => {
    const times: string[] = [];
    let currentTime = new Date();

    currentTime.setHours(orginalTime, 0, 0, 0); // Start at 08:00

    const endTime = new Date();
    endTime.setHours(19, 0, 0, 0); // End at 19:00

    while (currentTime <= endTime) {
      const timeString = currentTime.toLocaleTimeString([], {
        hour: "2-digit", // 2-digit hour (leading zero)
        minute: "2-digit",
        hour12: false, // 24-hour format
      });

      times.push(timeString);
      currentTime.setMinutes(currentTime.getMinutes() + 60); // Increment by 60 minutes
    }
    return times;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "contactNo") {
      if (/^\d*$/.test(value)) {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      if (name === "email" || name === "address") {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else if (name === "gst") {
        const value = e.target.value.toUpperCase();
        // Allow only alphanumeric characters
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value.replace(/[^a-zA-Z0-9]/g, ""),
        }));
      } else {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value.replace(/[^A-Za-z ]/gi, ""),
        }));
      }
    }
  };

  const handleDropdownChange = (e: DropDownListChangeEvent) => {
    const { name, value } = e.target;
    if (name === "Name") {
      setSelectedKYC(e.value);
      setSelectedKYCID(e.value.Id);
      setSelectedKYCName(e.value.Name);
    } else if (name === "bookingFrom") {
      setRoomBookFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      if (e.value === "Select From Time") {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          ["bookingTo"]: "Select To Time",
        }));
        setSelectedFromTime(defaultFromTimeValue);
      } else {
        setSelectedFromTime(e.value);
      }
      setSelectedToTime(defaultToTimeValue);
    } else {
      setSelectedDuration(e.value);
      setSelectedDurationName(e.value.DurationName);
      if (e.value.DurationName === "Select Duration") {
        calculateBookingAmount(0, 0, "", 0, 0);
        setDepositAmount(0);
        setIsCheckHourly(false);
      } else if (e.value.DurationName === "Hourly") {
        setsubTotal(e.value.RentAmount);
        setGstPercentage(e.value.Tax);
        setIsCheckHourly(false);
        setSelectedDurationID(e.value.DurationId);
        setDepositAmount(e.value.DepositAmmount);
        getPackageDetails(e.value.PackageDetailsId);
        calculateBookingAmount(0, 0, "Hourly", 0, e.value.DepositAmmount);
      } else {
        setIsCheckHourly(true);
        setSelectedFromTime(defaultFromTimeValue);
        setSelectedToTime(defaultToTimeValue);
        setSelectedDurationID(e.value.DurationId);
        setDepositAmount(e.value.DepositAmmount);
        getPackageDetails(e.value.PackageDetailsId);
        calculateBookingAmount(
          e.value.RentAmount,
          e.value.Tax,
          "Others",
          0,
          e.value.DepositAmmount
        );
      }
    }
  };

  const timeList = generateTimeList();

  const handleToTimeChange = (event: any) => {
    const selectedToTime = event.target.value;
    setSelectedToTime(selectedToTime);
    const { name, value } = event.target;
    setRoomBookFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    let fromTime: String = selectedFromTime!;
    // Parse hours and minutes from the time strings
    const [fromHours, fromMinutes] = fromTime.split(":").map(Number);
    const [toHours, toMinutes] = selectedToTime.split(":").map(Number);

    // Create Date objects for fromTime and toTime
    let fromDate = new Date();
    fromDate.setHours(fromHours, fromMinutes, 0, 0);

    let toDate = new Date();
    toDate.setHours(toHours, toMinutes, 0, 0);

    // Calculate the difference in hours
    const hoursDifference =
      (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60);
    calculateBookingAmount(0, 0, "Hourly", hoursDifference, depositAmount);
  };

  const calculateHoursDifference = (
    fromTime: string,
    toTime: string
  ): number => {
    const [fromHours, fromMinutes, fromPeriod] = fromTime.split(/[: ]/);
    let fromDate = new Date();
    fromDate.setHours(
      fromPeriod === "AM" ? parseInt(fromHours) : parseInt(fromHours) + 12,
      parseInt(fromMinutes),
      0,
      0
    );

    const [toHours, toMinutes, toPeriod] = toTime.split(/[: ]/);
    let toDate = new Date();
    toDate.setHours(
      toPeriod === "AM" ? parseInt(toHours) : parseInt(toHours) + 12,
      parseInt(toMinutes),
      0,
      0
    );

    return (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60);
  };

  const getFilteredToTimeList = (): string[] => {
    if (!selectedFromTime) return [];

    const filteredTimes = timeList.filter((time) => {
      const hoursDifference = calculateHoursDifference(selectedFromTime, time);
      return hoursDifference >= 1 && hoursDifference <= 5;
    });
    return filteredTimes;
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleDropdownListClose = () => {
    setSpaceVisible(false);
  };

  const handleDropdownListOpen = () => {
    setSpaceVisible(true);
  };

  const handleDropdownistChange = (event: any) => {
    setSelectedItem(event.target.value);
    getSpaceDetails(event.value.Id);
    setSpaceVisible(false);
  };

  return (
    <div>
      <AppBar className="header-row">
        <AppBarSection>
          <AppBarSection>
            <Link
              to="https://www.workfromhaven.in/"
              className="custom-link"
              style={{ textDecoration: "none" }}
            >
              <Icon className="back-icon" name="chevron-left" />
              <span style={{ marginLeft: "14px" }}>Back to Home</span>
            </Link>
          </AppBarSection>
        </AppBarSection>
        <AppBarSpacer style={{ flexGrow: 1 }} />
        <AppBarSection className="top-view">
          <img
            src={require("./assets/images/profile.png")}
            alt="Profile"
            style={{ height: "40px", borderRadius: "50%" }}
          />
        </AppBarSection>
      </AppBar>
      {loading && (
        <div className="center-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div className="body top-space">
          {spaceVisible && (
            <Dialog
              onClose={handleDropdownListClose}
              className="full-dialog-row"
            >
              <Label className="sub-heading-size">
                Select Space for booking
              </Label>
              <DropDownList
                className="personal-fields"
                textField="SpaceType"
                dataItemKey="Id"
                data={spaceDetailsData}
                value={selectedItem}
                defaultItem={{ SpaceType: "Select Space" }}
                onChange={handleDropdownistChange}
              />
            </Dialog>
          )}
          <div className="booking-form">
            <div className="form-row">
              <div className="form-group common-space">
                <Label>
                  Date<Label className="manditory-fields">*</Label>
                </Label>
                <MyDatePicker
                  checkInDate={checkInDate}
                  setCheckInDate={(e: any) => {
                    setCheckInDate(e);
                    setTimeCalc(e);
                  }}
                />
                <div>
                  {(checkInDate === undefined || checkInDate === null) &&
                    roomBookErrorFormData?.spaceDateErr && (
                      <Label className="error-field">
                        {roomBookErrorFormData?.spaceDateErr}
                      </Label>
                    )}
                </div>
              </div>
              <div className="form-group common-space">
                <Label>
                  Duration<Label className="manditory-fields">*</Label>
                </Label>
                <DropDownList
                  className="personal-fields"
                  textField="DurationName"
                  dataItemKey="Id"
                  data={durations}
                  value={selectedDuration}
                  onChange={handleDropdownChange}
                />
                <div>
                  {(selectedDuration.DurationName === "Select Duration" ||
                    selectedDurationName === "Select Duration") &&
                    roomBookErrorFormData?.spaceDurationErr && (
                      <Label className="error-field">
                        {roomBookErrorFormData?.spaceDurationErr}
                      </Label>
                    )}
                </div>
              </div>
            </div>
            {isCheckHourly === false && (
              <div className="form-row">
                <div className="form-group common-space">
                  <Label>From Time</Label>
                  <DropDownList
                    className="personal-fields"
                    data={timeList}
                    name="bookingFrom"
                    value={selectedFromTime}
                    onChange={(e) => {
                      handleDropdownChange(e);
                      settoTimeEnable(false);
                    }}
                  />
                  <div>
                    {(roomBookFormData?.bookingFrom === "" ||
                      roomBookFormData?.bookingFrom === "Select From Time" ||
                      selectedFromTime === "Select From Time") &&
                      roomBookErrorFormData?.bookingFromErrr && (
                        <Label className="error-field">
                          {roomBookErrorFormData?.bookingFromErrr}
                        </Label>
                      )}
                  </div>
                </div>
                <div className="form-group common-space">
                  <Label>To Time</Label>
                  <DropDownList
                    className="personal-fields"
                    data={getFilteredToTimeList()}
                    name="bookingTo"
                    value={selectedToTime}
                    disabled={totimeEnable} // Disable toTime until fromTime is selected
                    onChange={handleToTimeChange}
                  />
                  <div>
                    {(roomBookFormData?.bookingTo === "" ||
                      roomBookFormData?.bookingTo === "Select To Time" ||
                      selectedToTime === "Select To Time") &&
                      roomBookErrorFormData?.bookingToErrr && (
                        <Label className="error-field">
                          {roomBookErrorFormData?.bookingToErrr}
                        </Label>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="container">
            <div className="header-set">
              <Label className="sub-heading-size">Selected Room</Label>
              <div className="selected-room">
                <img
                  src={`https://live-stor.workfromhaven.in/${spaceData.SpaceImage}`}
                  className="small-padding"
                  style={{ height: 512, width: 512 }}
                />
                <Label className="heading-padding heading-size">
                  {spaceData.SpaceType}
                </Label>
                <div className="low-bottom-padding">
                  <Label className="sub-heading-size">Description</Label>
                  <p className="description-size">{spaceData.Description}</p>
                </div>
                <div className="low-bottom-padding">
                  <Label className="sub-heading-size">Package Details</Label>
                  {inclusionPackageDetails !== "" && (
                    <p className="description-size">{`Inclusion : ${inclusionPackageDetails}`}</p>
                  )}
                  {exclusionPackageDetails !== "" &&
                    exclusionPackageDetails !== "string" && (
                      <p className="description-size">{`Exclusion : ${exclusionPackageDetails}`}</p>
                    )}
                </div>
              </div>
            </div>
            <div className="header-set">
              <Label className="sub-heading-size">Personal Details</Label>
              <div className="personal-details">
                <div className="initial-field">
                  <Label className="field-label">Booking for ?</Label>
                  <Label className="manditory-fields">*</Label>
                  <br />
                  <div className="radio-group field-top">
                    <RadioButton
                      name="customerType"
                      value="Company"
                      checked={selectedValue === "Company"}
                      label="Company"
                      onChange={handleChange}
                    />
                    <div className="space" />
                    <RadioButton
                      name="customerType"
                      value="Individual"
                      checked={selectedValue === "Individual"}
                      label="Individual"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="detail-content">
                  {selectedValue === "Company" && (
                    <div className="remaining-field">
                      <Label className="field-label">Company Name</Label>
                      <Label className="manditory-fields">*</Label>
                      <Input
                        type="text"
                        placeholder="Enter Company Name"
                        className="personal-fields"
                        name="CompanyName"
                        value={roomBookFormData?.CompanyName}
                        maxLength={50}
                        onChange={handleInputChange}
                        required={
                          roomBookFormData?.CompanyName.trim() === "" &&
                          true &&
                          isCheckRequired?.companyNameErr === true
                        }
                      />
                      <div>
                        {roomBookFormData?.CompanyName.trim() === "" &&
                          roomBookErrorFormData?.companyNameErr && (
                            <Label className="error-field">
                              {roomBookErrorFormData?.companyNameErr}
                            </Label>
                          )}
                      </div>
                    </div>
                  )}
                  <div className="remaining-field">
                    <Label className="field-label">{currentName} Name</Label>
                    <Label className="manditory-fields">*</Label>
                    <Input
                      type="text"
                      placeholder="Enter Name"
                      className="personal-fields"
                      name="customerName"
                      value={roomBookFormData?.customerName}
                      maxLength={50}
                      onChange={handleInputChange}
                      required={
                        roomBookFormData?.customerName === "" &&
                        true &&
                        isCheckRequired?.companyNameErr === true
                      }
                    />
                    <div>
                      {roomBookFormData?.customerName.trim() === "" &&
                        roomBookErrorFormData?.customerNameErr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerNameErr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="remaining-field">
                    <Label className="field-label">Mobile Number</Label>
                    <Label className="manditory-fields">*</Label>
                    <Input
                      placeholder="Enter Mobile Number"
                      className="field-top personal-fields"
                      name="contactNo"
                      value={roomBookFormData?.contactNo}
                      maxLength={10}
                      onChange={handleInputChange}
                      required={
                        roomBookFormData?.contactNo === "" &&
                        true &&
                        isCheckRequired?.customerMobileErrr === true
                      }
                    />
                    <div>
                      {!roomBookFormData?.contactNo.trim() &&
                        roomBookErrorFormData?.customerMobileErrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerMobileErrr}
                          </Label>
                        )}
                      {roomBookFormData?.contactNo.trim() !== "" &&
                        (roomBookFormData?.contactNo.length < 10 ||
                          !mobileRegex.test(roomBookFormData.contactNo)) &&
                        roomBookErrorFormData?.customerMobileErrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerMobileErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="remaining-field">
                    <Label className="field-label">Email Address</Label>
                    <Label className="manditory-fields">*</Label>
                    <Input
                      className="field-top personal-fields"
                      placeholder="Enter Email Address"
                      name="email"
                      value={roomBookFormData?.email}
                      maxLength={50}
                      onChange={handleInputChange}
                      required={
                        roomBookFormData?.email === "" &&
                        true &&
                        isCheckRequired?.customerEmailErrr === true
                      }
                    />
                    <div>
                      {roomBookFormData?.email.trim() === "" &&
                        roomBookErrorFormData?.customerEmailErrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerEmailErrr}
                          </Label>
                        )}
                      {roomBookFormData?.email.trim() !== "" &&
                        !emailRegex.test(roomBookFormData.email) &&
                        roomBookErrorFormData?.customerEmailErrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerEmailErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="remaining-field">
                    <Label className="field-label">
                      {currentAddress} Address
                    </Label>
                    <Label className="manditory-fields">*</Label>
                    <TextArea
                      placeholder="Enter Address"
                      name="address"
                      defaultValue={roomBookFormData?.address}
                      onChange={handleInputChange}
                      required={
                        roomBookFormData?.address === "" &&
                        true &&
                        isCheckRequired?.customerAddresEerrr === true
                      }
                    />
                    <div>
                      {roomBookFormData?.address.trim() === "" &&
                        roomBookErrorFormData?.customerAddresEerrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerAddresEerrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="remaining-field">
                    <Label className="field-label">KYC Documents</Label>
                    <Label className="manditory-fields">*</Label>
                    <DropDownList
                      className="personal-fields"
                      textField="Name"
                      dataItemKey="Id"
                      name="Name"
                      data={kycDetailsData}
                      value={selectedKYC}
                      onChange={handleDropdownChange}
                    />
                    <div>
                      {(selectedKYC.Name === "Select KYC Document" ||
                        selectedKYCName === "Select KYC Document") &&
                        roomBookErrorFormData?.customerKYCFileErrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerKYCFileErrr}
                          </Label>
                        )}
                    </div>
                    <ExternalDropZone
                      className="field-space border-file-upload"
                      uploadRef={uploadRefs}
                      customHint={hint}
                      customNote={note}
                    />
                    <Upload
                      ref={uploadRefs}
                      restrictions={{
                        maxFileSize: 2000000,
                        allowedExtensions: [".jpeg", ".png", ".jpg", ".pdf"],
                      }}
                      batch={false}
                      multiple={false}
                      defaultFiles={[]}
                      withCredentials={false}
                      saveUrl={onSaveRequest}
                      removeUrl={onRemoveRequest}
                    />
                    <div>
                      {imageData === "" &&
                        roomBookErrorFormData?.customerKYCDocumentErrr && (
                          <Label className="error-field">
                            {roomBookErrorFormData?.customerKYCDocumentErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="remaining-field">
                    <Label className="field-label">GST Number</Label>
                    <Input
                      className="field-top personal-fields"
                      placeholder="Enter GST Number"
                      name="gst"
                      maxLength={15}
                      value={roomBookFormData?.gst}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="remaining-field">
                    <Label className="field-label">Note</Label>
                    <TextArea
                      placeholder="Enter Note"
                      name="notes"
                      defaultValue={roomBookFormData?.notes}
                      onChange={handleInputChange}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="header-set">
              <Label className="sub-heading-size">Bill Details</Label>
              <div className="bill-details">
                <div className="summary-container">
                  <div className="summary-item">
                    <span className="field-label total-color">Deposit</span>
                    <span className="field-text">₹ {depositAmount}</span>
                  </div>
                  <div className="summary-dotted-line" />
                  <div className="summary-item">
                    <span className="field-label total-color">Space Rent</span>
                    <span className="field-text">₹ {subTotalIncrease}</span>
                  </div>
                  <div className="summary-dotted-line" />
                  <div className="summary-item">
                    <span className="field-label total-color">Taxes</span>
                    <span className="field-text">₹ {taxAmount}</span>
                  </div>
                  <div className="summary-dotted-line" />
                  <div className="summary-item total">
                    <span className="summary-text">To Pay</span>
                    <span className="summary-price">₹ {dummyTotal}</span>
                  </div>
                </div>
                <div>
                  <Label className="cancel-heading">Cancellation Policy</Label>
                </div>
                <div className="total-color">
                  <ul>
                    <li>
                      Cancellation made 2 hours prior to the booking: 100%
                      refund.
                    </li>
                    <li>
                      Cancellation within 2 hours before the booking: 50%
                      cancellation fee.
                    </li>
                    <li>
                      No refund for cancellations made after the start of the
                      booking.
                    </li>
                  </ul>
                </div>
                <div className="button-view">
                  <Button
                    className="button-style"
                    onClick={(e) => savePersonalDetails(e)}
                  >
                    Confirm Booking
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {visible && (
              <Dialog
                title={"Message"}
                onClose={toggleDialog}
                style={{ alignItems: "center" }}
              >
                <Label
                  style={{
                    height: "80px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {alertContent}
                  <div
                    style={{
                      padding: "20px",
                    }}
                  />
                  <Button
                    className="alert-button"
                    type="button"
                    onClick={toggleDialog}
                  >
                    Ok
                  </Button>
                </Label>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
