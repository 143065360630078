import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";

import moment from "moment";

interface MyDatePickerProps {
  checkInDate: Date | null;
  setCheckInDate: (date: Date | null) => void;
}

function convertTZ(date: string, tzString: string) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export const MyDatePicker: React.FC<MyDatePickerProps> = ({
  checkInDate,
  setCheckInDate,
}) => {
  const datePickerWrapperRef = useRef<HTMLDivElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (datePickerWrapperRef.current) {
      const inputElement = datePickerWrapperRef.current.querySelector("input");
      if (inputElement) {
        inputElement.readOnly = true; // Disable manual input
      }
    }
  }, []);

  const handleDateChange = (e: DatePickerChangeEvent) => {
    const value = new Date(e.value?.toString().split("GMT")[0] + " UTC")
      .toISOString()
      .split(".")[0];
    setSelectedDate(e.value);
    const finalBookingDateStr = `${formatMomentTime(
      value,
      "YYYY-MM-DD"
    )}T${moment(new Date()).format("HH:mm:ss")}.000Z`;
    setCheckInDate(e.value);
  };

  const formatMomentTime = (dateStr: string, dateTimeFormat: string) => {
    const date = dateStr.split("-");
    const joinedDate = `${date[0]}-${date[1]}-${date[2]}`;
    const finalDateStr = joinedDate.replace("Z", "");
    const parsedDate = convertTZ(finalDateStr, "Asia/Kolkata");
    return moment(parsedDate).format(dateTimeFormat);
  };

  return (
    <div ref={datePickerWrapperRef}>
      <DatePicker
        value={checkInDate}
        onChange={handleDateChange}
        min={new Date()}
        placeholder={"DD/MM/YYYY"}
        format="EEE, dd/MM/yyyy"
        className="personal-fields"
        name="bookingDatetime"
      />
    </div>
  );
};
